<template>
  <div>
    <main>
      <HeaderNavFeature />
      <div class="main-info">
        <h1>About Us</h1>
      </div>
    </main>

    <div class="text">
      <div class="text-info">
        <div class="text-info-para">
          <p>
            HelixPay enables
            <b>world-class payments and ecommerce technology</b> for creators,
            communities, and events in the Philippines.
          </p>
          <p>
            Our clients create products and experiences that people love;
            <b>we build technology</b> that makes it easy to earn revenue and
            enable a seamless experience for their customers.
          </p>
        </div>

        <div class="year">
          <div class="box year1">
            <h1>2022</h1>
            <img src="/img/2022-image.png" alt="" />
            <p class="text-black">We are young company launched in 2022.</p>
          </div>
          <div class="box year1">
            <h1>SEA</h1>
            <img src="/img/sea-image.png" alt="" />
            <p class="text-black">Based in Manila; Built for Southeast Asia.</p>
          </div>
          <div class="box year1">
            <h1>Fintech</h1>
            <img src="/img/fintech-image.png" alt="" />
            <p class="text-black">
              World-class technology. Over 50% of HelixPay team are engineers.
            </p>
          </div>
          <div class="box year1">
            <h1>SaaS</h1>
            <img src="/img/saas-image.png" alt="" />
            <p class="text-black">
              Making technology accessible & empowering for Southeast Asia.
            </p>
          </div>
        </div>
      </div>

      <div class="our-investors">
        <div class="our-investors-info">
          <div class="our-investors-header">
            <h1>Our Investors</h1>
            <p>
              Backed by leading technology investors in the Philippines,
              California & around the world. With angel investors from Google,
              Tiktok and Alibaba.
            </p>
          </div>
          <div
            v-motion
            class="our-investors-img investors"
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          >
            <img src="/img/ventures.png" alt="" />
            <img src="/img/foxmont.png" alt="" />
            <img src="/img/rapyd.png" alt="" />
            <img src="/img/xa.png" alt="" />
            <img src="/img/elysium.png" alt="" />
          </div>
        </div>
      </div>

      <div class="our-investors">
        <div class="our-investors-info">
          <div class="our-investors-header">
            <h1>Our Partners</h1>
            <p>
              HelixPay works with the best payment & technology companies in the
              World to enable recurring revenue growth for our clients.
            </p>
          </div>
          <div
            v-motion
            class="our-investors-img partners"
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          >
            <img src="/img/maya.png" alt="" />
            <img src="/img/xendit.png" alt="" />
            <img src="/img/brankas.png" alt="" />
            <img src="/img/aws.png" alt="" />
          </div>
        </div>
      </div>

      <div class="our-investors">
        <div class="our-investors-info">
          <div class="our-investors-header">
            <h1>Our Regulatory Partners</h1>
            <p>
              HelixPay is proud to be fully compliant for operations in the
              Philippines.
            </p>
          </div>
          <div class="our-investors-img regulatory">
            <div class="regulatory-cards">
              <img
                v-motion
                src="/img/bsp.png"
                alt=""
                :initial="{
                  opacity: 0,
                  y: 100,
                }"
                :visibleOnce="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    stiffness: 200,
                    damping: 90,
                    mass: 3,
                  },
                }"
              />
              <p style="color: #858585; font-weight: 400">
                <b>Registered OPS</b>
              </p>
              <p>Registration: 4329025</p>
            </div>
            <div class="regulatory-cards">
              <img
                v-motion
                src="/img/pci.png"
                alt=""
                :initial="{
                  opacity: 0,
                  y: 100,
                }"
                :visibleOnce="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    stiffness: 200,
                    damping: 90,
                    mass: 3,
                  },
                }"
              />
              <p style="color: #858585; font-weight: 400">
                <b>Level 1 PCI DSS Certified</b>
              </p>
            </div>
            <div class="regulatory-cards">
              <img
                v-motion
                src="/img/npc.png"
                alt=""
                :initial="{
                  opacity: 0,
                  y: 100,
                }"
                :visibleOnce="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    stiffness: 200,
                    damping: 90,
                    mass: 3,
                  },
                }"
              />
              <p style="color: #858585; font-weight: 400"><b>Registered</b></p>
            </div>
            <div class="regulatory-cards">
              <img
                v-motion
                src="/img/sec.png"
                alt=""
                :initial="{
                  opacity: 0,
                  y: 100,
                }"
                :visibleOnce="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    stiffness: 200,
                    damping: 90,
                    mass: 3,
                  },
                }"
              />
              <p style="color: #858585; font-weight: 400">
                <b>Incorporated in Philippines</b>
              </p>
              <p>Corporate ID: 4329025</p>
            </div>
          </div>
        </div>
      </div>

      <div class="our-team">
        <div class="our-team-info">
          <div class="our-team-header">
            <div class="left-part">
              <div class="title">
                <h1>Our Team</h1>
              </div>
              <div class="join-us">
                <p>Want to join us?</p>
                <p style="color: #25a4e1; cursor: pointer">
                  <span
                    onclick="window.open('https://helixpay.ph/careers#view-openings')"
                  >
                    View Open Positions
                  </span>
                </p>
              </div>
            </div>
            <div class="right-part">
              <p>
                A talented team of builders, engineers and entrepreneurs in the
                Philippines
              </p>
            </div>
          </div>

          <div
            v-motion
            class="employees"
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          >
            <div class="mates">
              <div class="employee">
                <img src="/img/koger-min.png" alt="" />
                <h5>Andrew</h5>
                <h5>Co-Founder & CEO</h5>
              </div>
              <div class="employee">
                <img src="/img/asis-min.png" alt="" />
                <h5>Jeff</h5>
                <h5>Co-Founder & CTO</h5>
              </div>
              <div class="employee">
                <img src="/img/aninipot-min.png" alt="" />
                <h5>Princess</h5>
                <h5>Business<br />Development</h5>
              </div>
              <div class="employee">
                <img src="/img/loberiano-min.png" alt="" />
                <h5>Leah</h5>
                <h5>Compliance Officer</h5>
              </div>
              <div class="employee">
                <img src="/img/camatog-min.png" alt="" />
                <h5>Ryan</h5>
                <h5>Engineering</h5>
              </div>
              <div class="employee">
                <img src="/img/gamboa-min.png" alt="" />
                <h5>Jillian</h5>
                <h5>Head of Sales</h5>
              </div>
            </div>
            <div class="mates">
              <div class="employee">
                <img src="/img/carino-min.png" alt="" />
                <h5>Dexter</h5>
                <h5>Engineering</h5>
              </div>
              <div class="employee">
                <img src="/img/maimot-min.png" alt="" />
                <h5>Kenneth</h5>
                <h5>Finance</h5>
              </div>
              <div class="employee">
                <img src="/img/delara-min.png" alt="" />
                <h5>Kenneth</h5>
                <h5>Engineering</h5>
              </div>
              <div class="employee">
                <img src="/img/nava-min.png" alt="" />
                <h5>Gerald</h5>
                <h5>Office Admin</h5>
              </div>
              <div class="employee">
                <img src="/img/lim-min.png" alt="" />
                <h5>Zasha</h5>
                <h5>Business<br />Development</h5>
              </div>
              <div class="employee">
                <img src="/img/pineda-min.png" alt="" />
                <h5>Jonan</h5>
                <h5>Engineering</h5>
              </div>
            </div>
            <div class="mates">
              <div class="employee">
                <img src="/img/mirandilla-min.png" alt="" />
                <h5>Issa</h5>
                <h5>Engineering</h5>
              </div>
              <div class="employee">
                <img src="/img/vidal-min.png" alt="" />
                <h5>Ginger</h5>
                <h5>Business<br />Development</h5>
              </div>
              <div class="employee">
                <img src="/img/ogayon-min.png" alt="" />
                <h5>Jason</h5>
                <h5>Engineering</h5>
              </div>
              <div class="employee">
                <img src="/img/deleon-min.png" alt="" />
                <h5>Marielle</h5>
                <h5>Graphic Designer</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-us">
        <div class="contact-us-info">
          <div class="contact-left">
            <h1>Contact Us</h1>
            <h4 style="color: #25a4e1; font-weight: 700">HelixPay Office</h4>

            <div class="location">
              <img src="/img/about-us-hp.svg" class="about-us-images" alt="" />
              <p>
                Corinthian Plaza 121 Paseo de Roxas Street Legazpi Village,
                Makati Metro Manila, Philippines
              </p>
            </div>

            <p class="greenbelt">Next to Greenbelt1!</p>

            <div class="directions location">
              <img src="/img/directions.png" class="about-us-images" alt="" />
              <p
                style="cursor: pointer"
                onclick="window.open('https://www.waze.com/live-map/directions/ph/ncr/makati/corinthian-plaza?to=place.ChIJ_flWKg7JlzMROb4n3Jw5IgA')"
              >
                Click for Directions
              </p>
            </div>

            <p style="color: #25a4e1; font-weight: 700; margin-top: 2rem">
              Contact Us
            </p>

            <div class="directions location">
              <img src="/img/gmail.png" class="about-us-images" alt="" />
              <p>hello@helixpay.ph</p>
            </div>
            <div class="directions location">
              <img src="/img/facebook.png" class="about-us-images" alt="" />
              <p>HelixPay</p>
            </div>
          </div>
          <div class="contact-right">
            <img src="img/helixpay-map.svg" alt="" />
          </div>
        </div>
      </div>

      <LaunchSubscriptions />
    </div>

    <MainFooter />
  </div>
</template>

<script>
import HeaderNavFeature from '@/components/HeaderNavFeature.vue';
import LaunchSubscriptions from '@/components/LaunchSubscriptions.vue';
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'AboutUs',
  components: {
    HeaderNavFeature,
    LaunchSubscriptions,
    MainFooter,
  },

  setup() {
    useHead(
      buildPageMeta({
        title: 'About Us | Ecommerce Technology for Creators & Communities',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/about',
        image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
      })
    );
  },
};
</script>

<style scoped>
main {
  background-image: linear-gradient(to right, #25a4e1, #9affe1);
}

.main-info {
  width: 71rem;
  margin: auto;
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.main-info h1 {
  color: #142d4a;
  font-weight: 700;
  font-size: 2.5rem;
}

.text {
  width: 100%;
  background-image: url('/img/about-us-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-info {
  width: 71rem;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.text-info-para > p {
  width: 60%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
  color: #142d4a;
}

.text-info-para p:nth-child(2) {
  padding-top: 3rem;
}

.year {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3rem;
  margin-top: 8rem;
  padding: 2rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.box img {
  width: 50%;
}

.box:nth-child(2) img {
  width: 55%;
}

.box h1 {
  font-weight: 700;
  color: #25a4e1;
}

.box p {
  font-weight: 500;
  opacity: 0.6;
  line-height: 2;
}

.our-investors {
  width: 100%;
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.our-investors-info {
  width: 71rem;
  margin: auto;
}

.our-investors-header {
  width: 90%;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  align-items: center;
  margin: auto;
}

.our-investors-header h1 {
  font-weight: 700;
}

.our-investors-header p,
.right-part p {
  font-weight: 500;
  opacity: 0.6;
  line-height: 2;
}

.our-investors-img {
  width: 90%;
  margin: auto;
  justify-content: center;
  gap: 3rem;
  margin-top: 4rem;
}

.investors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.partners,
.regulatory {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.our-investors-img img {
  margin: auto;
}

.regulatory {
  align-items: center;
}

.regulatory-cards {
  align-items: center;
  text-align: center;
}

.regulatory-cards img {
  margin-bottom: 2rem;
}

.regulatory-cards p:nth-child(2) {
  margin-top: 1rem;
}

.regulatory-cards p:nth-child(3) {
  font-weight: 500;
  margin-top: 0.5rem;
  opacity: 0.6;
}

.our-team {
  width: 100%;
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.our-team-info {
  width: 71rem;
  margin: auto;
}

.our-team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-part {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.our-team-header h1 {
  font-weight: 700;
}

.join-us {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  font-weight: 700;
}

.right-part {
  width: 30%;
}

.employees {
  margin-top: 4rem;
}

.president {
  width: 10rem;
  margin: auto;
}

.mates {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 3rem;
}

.mates:nth-child(2),
.mates:nth-child(3),
.mates:nth-child(4),
.mates:nth-child(5),
.mates:nth-child(6),
.mates:nth-child(7) {
  margin-top: 3rem;
}

.employee {
  text-align: center;
}

.employee h5 {
  font-weight: 700;
  font-size: 0.75rem;
}

.employee h5:nth-child(2) {
  margin-top: 1rem;
}

.employee h5:nth-child(3) {
  color: #25a4e1;
  margin-top: 0.3rem;
}

.employee img {
  width: 90%;
  border-radius: 50%;
}

.contact-us {
  width: 100%;
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.contact-us-info {
  width: 71rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}

.location {
  display: grid;
  gap: 1rem;
}

.location p {
  font-weight: 700;
  opacity: 0.6;
}

.greenbelt {
  margin-top: 2rem;
  font-weight: 700;
  opacity: 0.6;
}

.directions {
  display: flex;
  align-items: center;
}

.directions p {
  margin-top: 2rem;
}

.contact-left {
  width: 30%;
}

.contact-left h1 {
  font-weight: 700;
}

.contact-left h4 {
  font-size: 1.3rem;
}

.about-us-images {
  width: 15%;
}

.contact-left img {
  margin-top: 2rem;
}

.contact-right img {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .main-info {
    width: 90%;
  }

  .text-info {
    width: 100%;
  }

  .text-info-para > p {
    width: 90%;
    margin: auto;
  }

  .year {
    width: 90%;
  }

  .our-team-info {
    width: 90%;
    margin: auto;
  }

  .mates {
    grid-template-columns: repeat(3, 1fr);
  }

  .president {
    width: 20%;
  }

  .mates img {
    width: 60%;
  }

  .employee h5 {
    font-size: 0.7rem;
  }

  .contact-us-info {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .year {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: center;
  }

  .box img {
    width: 50%;
    margin: auto;
  }

  .our-investors-img {
    grid-template-columns: 1fr 1fr;
  }

  .our-investors-info {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .text {
    background-position: center center;
  }

  .our-investors-header {
    grid-template-columns: 1fr;
  }

  .left-part {
    gap: 0;
  }

  .our-investors-header h1 {
    font-size: 1.5rem;
  }

  .left-part {
    display: grid;
  }

  .right-part {
    width: 100%;
  }

  .right-part p {
    margin-top: 1rem;
  }

  .our-team-header {
    display: grid;
  }

  .president {
    width: 30%;
  }

  .employee img {
    width: 90%;
  }
}

@media only screen and (max-width: 650px) {
  .text-info-para p {
    font-size: 1rem;
  }

  .year {
    margin-top: 4rem;
  }

  .box img {
    width: 40%;
  }

  .our-investors-img img {
    width: 50%;
    margin: auto;
  }

  .our-investors-img img {
    width: 70%;
  }

  .regulatory-cards img {
    width: 50%;
  }

  .regulatory-cards:nth-child(2) img {
    margin-bottom: 1.5rem;
  }

  .contact-us-info {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .contact-left {
    width: 100%;
  }

  .contact-right img {
    display: none;
  }

  .about-us-images {
    width: 8%;
  }
}
</style>
